import styled from 'styled-components'
import { Container } from '../common'

import water from '../../static/imgs/water.jpg'

const Footer = styled.div`
    padding: 2em 0;
    background: url(${water}) no-repeat center center;
    background-size: cover;
    background-color: #F7FCFF;
    background-blend-mode: multiply;
    padding-top:  200px;
    p {
        font-size: 10px;
        max-width:300px;
        opacity:  0.666;
    }
`
const index = () => {
    return (
        <Footer>
            <Container>
                <p>
                    © 2021. Kanan Mineral Water Limited. All rights reserved. Distributed in Kenya by Kanan Mineral Water Limited. Kanan Mineral Water Limited is the owner of Kanan Water trademark, all related designs and other intellectual properties.
                </p>
            </Container>
        </Footer>
    )
}

export default index