import styled from 'styled-components'
import { Container } from '../../../components/common'

import drink  from '../../../static/imgs/drink.png'
import kanan  from '../../../static/imgs/kanan.png'
import pour  from '../../../static/imgs/pour.png'

const InfoContainer = styled.div`
    min-height: 10vh;
`
const Info = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    p {
        max-width: 400px;
        font-size:1.2em;
        line-height: 1.5em;
        display: inline-block;
        vertical-align: middle !important;
    }
    img {
        width: 100%;
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        font-size: 0.7rem;
    }

`

const About = () => {
    return (
        <InfoContainer>
            <Container>
                <Info>
                    <p>
                        Founded in 2017, Kanan  Mineral Water Limited is a company providing an assortment if clean, crisp-tasting water products to retailers across the Rift Valley region. Kanan delivers to homes and offices in Lodwar town in Turkana County and selected areas.
                    </p>
                    <img src={pour} alt="pour kanan water" />
                </Info>
                <Info>
                    <img src={drink} alt="founder kanan water" />
                    <p>
                        Today, Kanan Water offers a wide variety of bottled water. This includes natural spring water, steam distilled water and purified drinking water. Kanan Water comes in a variety of sizes and packaging. Ranging from individual bottles and cases to 5, 10 ad 20 litres gallon jugs.
                    </p>
                </Info>
                <Info>
                    <p>
                        We are the first water company in Turkana County. While our humble beginnings date back more than three years. We have evolved our products and services to meet the growing and changing needs of our clients.  We also provide customized water bottles, cups and hand dispensers.
                    </p>
                    <img src={kanan} alt="kanan water" />
                </Info>
            </Container>
        </InfoContainer>
    )
}

export default About
