import styled from "styled-components"
import { Container } from "../common"
import { Link } from 'react-router-dom'

import Nav from "./Nav"

import logo from '../../static/imgs/logo.png'

const HeaderContainer = styled.div`
    background: linear-gradient(90deg, rgba(208,234,248,1) 0%, rgba(208,234,248,1) 55%, rgba(67,151,183,1) 100%);
    margin-bottom: -20px;
`

const Logo = styled.div`
    height: 72px;
    width: 90px;
    background-color: #D0EAF8;
    background-image: url(${logo});
    background-blend-mode: multiply;
    background-size: cover;
`

const HeaderWrapper = styled.div`
    display: grid;
    grid-template-columns: 150px auto;
    @media (max-width: 768px) {
        display: grid;
        grid-template-columns: 100px auto;
    }
`
const Header = () => {
    return (
        <HeaderContainer>
            <Container>
                <HeaderWrapper>
                    <Link to="/"><Logo /></Link>
                    <Nav />
                </HeaderWrapper>
            </Container>
        </HeaderContainer>
    )
}

export default Header
