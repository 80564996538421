import { Route, Switch } from 'react-router-dom'

import '../App.css';

import Header from '../components/header'
import Footer from '../components/footer'

import Contacts from './contact'
import Home from './home'


const Main = () => {
    return (
        <>
            <Header/>
                <Switch>
                    <Route path="/" exact><Home /></Route>
                    <Route path="/contacts"><Contacts /></Route>
                </Switch>
            <Footer/>
        </>
    )
}

export default Main
