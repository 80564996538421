// import { Link } from 'react-router-dom'
import Stats from './stats';
import About from './about';
import { Container } from '../../components/common';

import cheers from '../../static/imgs/cheers.png'
import styled from 'styled-components';

const Cheer = styled.img`
  width:100%
`

/* const CTA = styled.button`
  border-radius: 38.5px;
  background: #2b42b5;
  letter-spacing: 0.15em;
  color: #effaff;
  opacity: 0.9;
  padding: 20px 30px;
  border: none;
` */

function HomeIndex() {
  return (
    <>
      <div className="drop"></div>
      <div className="home">
        <Container>
          <div className="kanan-header">
            <h1>More than water</h1>
            <p>Clean, crisp-tasting.</p>
          </div>
        </Container>
      </div>
      <Stats />
      <Cheer src={cheers} alt="Cheers Kanan Water" />
      <About />
    </>
  );
}

export default HomeIndex;
