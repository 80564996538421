import styled  from 'styled-components'
import AnimatedNumber from 'animated-number-react'
import { Container } from '../../components/common'

const StatContainer = styled.div`
    border-top: 2px solid #2B42B5;
    background-color: #4397B7;
    padding: 0.5em 0 0.7em 0;
    font-size: 2em;
`
const StatWrapper = styled.div`
    display: grid;

    @media (min-width: 768px) {
        grid-template-columns: 33% 33% 33%;
    }
`
const Stat = styled.div`
    display: flex;
    align-items: flex-end;
    color: #effaff;
    font-weight: 500 !important;
    line-height: 12px;
    h1 {
        font-family: Neusa;
        line-height: 0;
    }
    p {
        font-size: 13px;
        line-height: 14px;
        max-width:120px;
        padding: 4px 15px;
    }
    
    @media (max-width: 768px) {

        h1 {
            font-size: 1.4em;
    }
        display: grid;
        grid-template-columns: 33% auto;
    }
`

const formatValue = v => v.toFixed(1)
const Stats = () => {
    return (
        <StatContainer>
            <Container>
                <StatWrapper>
                    <Stat>
                        <h1>
                            <AnimatedNumber
                                value={6.7}
                                begin={0}
                                duration={4000}
                                formatValue={formatValue}
                            />M
                        </h1>
                        <p>Bottles of water sold</p>
                    </Stat>
                    <Stat>
                        <h1>
                            <AnimatedNumber
                                value={10.8}
                                duration={7000}
                                begin={0}
                                formatValue={formatValue}
                            />M
                        </h1>
                        <p>Litres of clean water processed</p>
                    </Stat>
                    <Stat>
                        <h1>
                            <AnimatedNumber
                                value={1.2}
                                begin={0}
                                duration={5000}
                                formatValue={formatValue}
                            />K</h1>
                        <p>Direct and indirect jobs created</p>
                    </Stat>
                </StatWrapper>
            </Container>
        </StatContainer>
    )
}

export default Stats
