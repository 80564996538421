import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { SocialIcon } from 'react-social-icons'

const NavContainer = styled.div`
    display: grid;
    grid-template-columns: 80% 20%;
    padding-top: 30px;
    .link {
        text-decoration: none;
        color: #211f22;
        margin-right:50px;
        font-size: 1.2em;
    }
    .social {
        height: 30px !important;
        width: 30px !important;
        margin-left: 20px;
        margin-bottom: 5px;
        float: right !important;
    }
    a:hover, .activeLink {
        font-weight: bolder;
        cursor: pointer;
    }

    @media (max-width: 768px) {
        font-size: 0.7em;
        padding-top: 0;
        .link {
            border-left: 1px solid #ccc;
            padding-left: 10px; 
            margin-right:10px;
        }
    }

`
const Nav = () => {
    return (
        <NavContainer>
            <div>
                <NavLink
                    activeClassName="activeLink"
                    className="link"
                    exact
                    to="/"
                >
                    Our story
                </NavLink>
                <NavLink
                    activeClassName="activeLink"
                    className="link"
                    to="/contacts"
                >
                    Contact us
                </NavLink>
            </div>
            <div>
                <SocialIcon className="social" url="https://www.instagram.com/kanan_waters"/>
                <SocialIcon className="social" url="https://facebook.com/kananwater"/>
            </div>
        </NavContainer>
    )
}

export default Nav
