import styled from "styled-components"
import { Container } from "../../components/common"
import ContactForm from './ContactForm'

const ContactContainer = styled.div`
    min-height: 60vh;
`
const ContactWrapper = styled.div`
    display: grid;
    margin-top: 10vh;
    @media (min-width: 768px) {
        grid-template-columns: 25% auto 20%; 
    }
`
const Contacts = () => {
    return (
        <ContactContainer>
            <Container>
                <ContactWrapper>
                    <div>
                    info@kanawater.co.ke<br />
                    <b>+254 (0) 743 911 999</b>
                    <br/>
                    <br/>
                    <br/>
                    </div>
                    <div><ContactForm/></div>
                </ContactWrapper>
            </Container>
        </ContactContainer>
    )
}

export default Contacts
