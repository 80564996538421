import { useFormik } from "formik";
import styled from "styled-components";

const Submit = styled.button`
  background: none;
  border: none;
  padding: 0;
  font-weight: bold;
  font-family: Avenir, sans-serif;
  font-size: 1.2em;
  :hover {
    cursor: pointer;
    color: green;
  }
`
const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Required";
  } else if (values.name.length > 15) {
    errors.name = "Must be 15 characters or less";
  }

  if (!values.subject) {
    errors.subject = "Required";
  } else if (values.subject.length > 20) {
    errors.subject = "Must be 20 characters or less";
  }

  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validate,
    onSubmit: async (values, { setSubmitting }) => {
      const { email, name, subject, message } = values
      const data = {
        to: 'info@kananwater.co.ke',
        from: 'info@kananwater.co.ke',
        subject: subject,
        text: `${message} -${name}, ${email}`,
        html: `Dear Customer Service,
        ${name} of email ${email} sent a message:
        <i>${message}</i>
        Regards`
      }

      console.log(data)

      const res = await fetch(process.env.REACT_APP_SG_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'secret': process.env.REACT_APP_SECRET
        },
        body: JSON.stringify(data)
      })

      console.log(res)
      formik.resetForm()
      setSubmitting(false)
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <label htmlFor="name">Full Names</label>
      <input
        id="name"
        name="name"
        placeholder="Full name"
        type="text"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
      />
      {formik.errors.name ? <div>{formik.errors.name}</div> : null}
      <br />
      <br />
      <br />
      <label htmlFor="email">Email Address</label>
      <input
        id="email"
        name="email"
        type="email"
        placeholder="your@email.com"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
      />
      {formik.errors.email ? <div>{formik.errors.email}</div> : null}

      <br />
      <br />
      <br />
      <label htmlFor="subject">Subject</label>
      <input
        id="subject"
        name="subject"
        type="text"
        placeholder="Subject"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.subject}
      />
      {formik.errors.subject ? <div>{formik.errors.subject}</div> : null}

      <br />
      <br />
      <br />
      <label htmlFor="message">Message</label>
      <div className="grow-wrap">
        <textarea
          id="message"
          name="message"
          placeholder="What can we help you with?"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
        />
      </div>
      {formik.errors.message ? <div>{formik.errors.message}</div> : null}

      <br />
      <br />
      <br />
      <Submit
        type="submit"
        disabled={formik.isSubmitting}
      >
        { formik.isSubmitting ? 'Sending your message ...' : 'Send →'}
      </Submit>
    </form>
  );
};

export default ContactForm;
